/// <reference path="Home.ts" />
/// <reference path="Footer.ts" />

namespace OSS.WebFront {
    $(() => {
        Home.initialize($("body.home"));
        Footer.initialize($("body > footer"));
        initializeTabMenus();
    });

    function initializeTabMenus() {
        const options: JQueryUI.TabsOptions = { activate: onTabActive };
        $("#activities-tabs, #services-tabs").tabs(options).fadeIn(200);

        function onTabActive(e: JQueryEventObject, ui) {
            const dummy = document.createElement("a");
            dummy.href = window.location.toString();
            dummy.hash = String(ui.newPanel.attr("id"));
            window.history.pushState({}, document.title, dummy.href);
            return false;
        }
    }

}