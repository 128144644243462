/// <reference path="_references.ts" />

namespace OSS.WebFront.Home {
    export function initialize($root: JQuery) {
        if ($root.length === 0)
            return;

        initializeActivities($root);
        initializeSlideshow($root);
    }

    function initializeActivities($root: JQuery) {
        const labels = $root.find(".home-activities-carousel-container li label");
        labels.show();
        labels.each((index, element) => new CircleType(element).radius(90));

        if ($(".home-activities-summaries").is(":visible"))
            $(".home-activities-container li[data-type=airlines]").addClass("active");

        $(".home-activities-carousel-container li").on("mouseover",
            e => {
                const $target = $(e.currentTarget);
                $(".home-activities-container li").removeClass("active");
                $(`.home-activities-container li[data-type=${$target.data("type")}]`).addClass("active");
            });
    }

    function initializeSlideshow($root: JQuery) {
        const $slideshow = $("body>header .home-slideshow");
        if ($slideshow.length === 0)
            return;

        $slideshow.find("#home-slideshow-previous").on("click", onHeaderSlidePreviousClick);
        $slideshow.find("#home-slideshow-next").on("click", onHeaderSlideNextClick);

        const $slideContainer = $slideshow.find("ul");
        if ($slideContainer.length === 0)
            return;

        const autoInterval = Number($slideContainer.data("autointerval"));
        if (isNaN(autoInterval) || autoInterval === 0)
            return;

        setTimeout(() => autoSlide(), autoInterval);

        function autoSlide() {
            headerSlide($slideContainer, true);
            setTimeout(autoSlide, autoInterval);
        }
    }

    function onHeaderSlidePreviousClick(e: JQueryEventObject) {
        e.preventDefault();
        e.stopPropagation();
        headerSlide($(e.currentTarget).siblings("ul"), false);
    }

    function onHeaderSlideNextClick(e: JQueryEventObject) {
        e.preventDefault();
        e.stopPropagation();
        headerSlide($(e.currentTarget).siblings("ul"), true);
    }

    function headerSlide($slideContainer: JQuery<Element>, showNext: boolean) {
        const $slides = $slideContainer.children("li");
        let currentIndex = 0;
        let $currentSlide: JQuery<Element>;
        $slides.each((i, elem) => {
            if ($(elem).is(":visible")) {
                currentIndex = i;
                $currentSlide = $(elem);
            }
        });

        function getNextIndex(count: number, showNext: boolean) {
            if (showNext)
                return currentIndex < (count - 1) ? currentIndex + 1 : 0;
            else
                return currentIndex > 0 ? currentIndex - 1 : count - 1;
        }

        const nextIndex = getNextIndex($slides.length, showNext);
        $currentSlide.fadeOut();
        $slides.eq(nextIndex).fadeIn();
    }
}