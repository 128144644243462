/// <reference path="_references.ts" />

namespace OSS.WebFront.Footer {
    export function initialize($root: JQuery) {
        const $counters = $root.find(".footer-counters");
        if ($counters.length === 0)
            return;

        const $outputs = $counters.find("output");
        $outputs.each(initializeFooterCounter);

        function initializeFooterCounter(index: number, element: HTMLElement) {
            const $element = $(element);
            const originalValue = String($(element).val());
            const targetValue = Number(originalValue);
            if (isNaN(targetValue))
                return;

            const precision = getPrecision(targetValue);
            $element.val(Number(0).toFixed(precision));

            if (isInView())
                startCounterIncrement();
            else
                $(window).on("scroll", onScroll);

            function onScroll(e: JQueryEventObject) {
                if (isInView()) {
                    startCounterIncrement();
                    $(window).off("scroll", onScroll);
                }
            }

            function isInView() {
                const hT = $element.offset().top;
                const hH = $element.outerHeight();
                const wH = $(window).height();
                const wS = $(this).scrollTop();
                return (wS > (hT + hH - wH));
            }

            function startCounterIncrement() {
                $counters.find("ul").css("visibility", "visible");

                const stepCount = 100;
                let interval = Number($element.data("interval"));
                if (isNaN(interval) || interval <= 0)
                    interval = 20;

                setTimeout(() => incrementCounter(1), interval);

                function incrementCounter(step: number) {
                    if (step === stepCount) {
                        $element.val(originalValue);
                        return;
                    }

                    $element.val(((targetValue * step) / 100.0).toFixed(precision));
                    setTimeout(() => incrementCounter(step + 1), interval);
                }
            }

            function getPrecision(n: number): number {
                if (!isFinite(n))
                    return 0;

                let e = 1;
                let p = 0;
                while (Math.round(n * e) / e !== n) {
                    e *= 10;
                    ++p;
                }

                return p;
            }
        }
    }
}